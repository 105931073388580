<div class="location-header container mx-auto" *ngIf="club">
  <div class="blurred-background-outer">
    <div
      class="blurred-background"
      [ngStyle]="{'background-image': backgroundImage}"
    ></div>
    <!--<canvas class="blurred-background"  #myId></canvas>-->
  </div>

  <div class="grid gap-4 lg:grid-cols-3 md:grid-cols-1 sm:grid-cols-1">
    <div
      class="cover-image"
      #coverImage
      [ngStyle]="{'background-image': backgroundImage}"
    ></div>
    <div class="title-block lg:col-span-2">
      <h1>{{ club.clubName }}</h1>
      {{ club.clubSlogan }}
    </div>
  </div>
  <div class="action-buttons flex items-end sm:items-center">
    <ion-button
      fill="clear"
      *ngIf="club.facebook"
      color="light"
      (click)="functions.goToPage('https://www.facebook.com/' + club.facebook)"
    >
      <ion-icon name="logo-facebook"></ion-icon>
    </ion-button>
    <ion-button
      fill="clear"
      *ngIf="club.instagram"
      color="light"
      (click)="functions.goToPage('https://www.instagram.com/' + club.instagram)"
    >
      <ion-icon name="logo-instagram"></ion-icon>
    </ion-button>

    <ion-button
      fill="clear"
      color="light"
      (click)="functions.goToPage('https://maps.google.com/?q=' + functions.encodeURIComponent(club.clubName) + ' ' + functions.encodeURIComponent(club.location))"
    >
      <ion-icon name="navigate"></ion-icon>
    </ion-button>
  </div>
</div>
