import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AboutUsPageModule } from 'src/pages/about-us/about-us.module';
import { CareerPageModule } from 'src/pages/career/career.module';
import { ContactPageModule } from 'src/pages/contact/contact.module';
import { HomePageModule } from 'src/pages/home/home.module';
import { ImprintPageModule } from 'src/pages/imprint/imprint.module';
import { NewEventPageModule } from '../pages/new-event/new-event.module';
import {LocationsPageModule} from "../pages/locations/locations.module";
import {LocationPageModule} from "../pages/location/location.module";

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'imprint',
    loadChildren: () => ImprintPageModule,
  },
  {
    path: 'home',
    loadChildren: () => HomePageModule,
  },
  {
    path: 'locations',
    loadChildren: () => LocationsPageModule,
  },
  {
    path: 'club',
    loadChildren: () => LocationPageModule,
  },
  {
    path: 'new-event',
    loadChildren: () => NewEventPageModule,
  },
  {
    path: 'about-us',
    loadChildren: () => AboutUsPageModule,
  },
  {
    path: 'contact',
    loadChildren: () => ContactPageModule,
  },
  {
    path: 'career',
    loadChildren: () => CareerPageModule,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
