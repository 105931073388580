<ng-template #withFlags let-attrs="attrs">
  <!--<img [src]="attrs.data.logoGuid | image | async" class="flag" />-->
  <span
    [innerHTML]="(attrs.data.location + ' (' + attrs.data.country + ')') | boldprefix:attrs.keyword"
  ></span>
</ng-template>

<form [formGroup]="searchform" (ngSubmit)="submit()" novalidate>
  <div class="ion-form-group">
    <ion-auto-complete
      [dataProvider]="searchService"
      [template]="withFlags"
      [options]="{ placeholder : 'Suchen ...' }"
      (itemSelected)="onSelect($event)"
      formControlName="searchField"
    ></ion-auto-complete>
  </div>

  <!--<button ion-button icon-only type="submit">
    <ion-icon name="search"></ion-icon>
  </button>-->
</form>
