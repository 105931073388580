import { NgModule } from '@angular/core';
import { TruncatePipe } from './truncate/truncate';
import {ImagePipe} from "@common/pipes/image/image";
@NgModule({
  declarations: [TruncatePipe, ImagePipe],
  imports: [],
  exports: [TruncatePipe, ImagePipe],
  providers: [TruncatePipe, ImagePipe],
})
export class PipesModule {}
