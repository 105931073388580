import { NgModule } from '@angular/core';
import { HeaderComponent } from './header/header';
import { FooterComponent } from './footer/footer';
import { EventItemComponent } from './event-item/event-item';
import { EventCategoryItemComponent } from './event-category-item/event-category-item';
import { CityItemComponent } from './city-item/city-item';
import { SearchComponent } from './search/search';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AutoCompleteModule } from 'ionic4-auto-complete';
import {PipesModule} from "@common/pipes/pipes.module";

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    EventItemComponent,
    EventCategoryItemComponent,
    CityItemComponent,
    SearchComponent,
  ],
    imports: [
        RouterModule,
        IonicModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        AutoCompleteModule,
        PipesModule,
    ],
  exports: [
    HeaderComponent,
    FooterComponent,
    EventItemComponent,
    EventCategoryItemComponent,
    CityItemComponent,
    SearchComponent,
  ],
})
export class ComponentsModule {}
