import { Pipe, PipeTransform } from '@angular/core';
import { MediaService } from '../../../api/services/media.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'image',
})
export class ImagePipe implements PipeTransform {
  constructor(private media: MediaService, private sanitizer: DomSanitizer) { }

  transform(guid: string): Observable<SafeUrl> {
    return this.media
      .apiMediaImagePost({ body: { guid } })
      .pipe(map(val => this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(val))));
  }
}
