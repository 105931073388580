import { Component, Input } from '@angular/core';

@Component({
  selector: 'event-category-item',
  templateUrl: 'event-category-item.html',
  styleUrls: ['./event-category-item.scss'],
})
export class EventCategoryItemComponent {
  @Input() data: any;

  constructor() {}
}
