import {Component, Input, OnInit} from '@angular/core';
import { ImagePipe } from "@common/pipes/image/image";
import { SafeUrl } from "@angular/platform-browser";
import {GetLocationFilterResponseEntry} from "../../../api/models/get-location-filter-response-entry";

@Component({
  selector: 'city-item',
  templateUrl: 'city-item.html',
  styleUrls: ['./city-item.scss'],
})
export class CityItemComponent implements OnInit {
  @Input() data: GetLocationFilterResponseEntry;
  private _backgroundImage: SafeUrl;

  constructor(private imagePipe: ImagePipe) {}

  public get backgroundImage() {
    return `url(${(this._backgroundImage as any)?.changingThisBreaksApplicationSecurity})`;
  }

  async ngOnInit(): Promise<void> {
    this._backgroundImage = await this.imagePipe.transform(this.data.logoGuid).toPromise();
  }
}
