import { Component, OnInit } from '@angular/core';
import {
  NavController,
  ModalController,
  LoadingController,
} from '@ionic/angular';
import {ClubService} from "../../api/services/club.service";
import {GetLocationFilterResponseEntry} from "../../api/models/get-location-filter-response-entry";

@Component({
  selector: 'cm-home',
  templateUrl: './home.page.html',
  styleUrls: ['./home.page.scss'],
})
export class HomePage implements OnInit {
  searchTerm = '';
  items: any = [];
  locations?: Array<GetLocationFilterResponseEntry>;

  constructor(
    public navCtrl: NavController,
    public modalCtrl: ModalController,
    public loadingCtrl: LoadingController,
    public clubService: ClubService
  ) {}

  async ngOnInit() {
    this.locations = (await this.clubService.apiClubFilterInfoLocationPost$Json().toPromise()).locationFilterEntries.slice(0, 12);
  }

  goToLocation(data?) {
    this.navCtrl.navigateForward(data ? ['locations', `${data.id}-${data.location}`] : ['locations']);
  }
}
