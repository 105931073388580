import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { LocationsPageRoutingModule } from './locations-routing.module';

import { LocationsPage } from './locations.page';
import { LocationListEntryComponent } from './components/location-list-entry/location-list-entry';
import { ComponentsModule } from '@common/components/components.module';
import { PipesModule } from '@common/pipes/pipes.module';
import { TooltipModule } from 'ng2-tooltip-directive';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    LocationsPageRoutingModule,
    ComponentsModule,
    PipesModule,
    TooltipModule,
  ],
  declarations: [LocationsPage, LocationListEntryComponent],
})
export class LocationsPageModule {}
