/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { GetAgeFilterResponse } from '../models/get-age-filter-response';
import { GetBasicClubInfoForFilterResponse } from '../models/get-basic-club-info-for-filter-response';
import { GetClubCountForFilterResponse } from '../models/get-club-count-for-filter-response';
import { GetClubCountForLocationRequest } from '../models/get-club-count-for-location-request';
import { GetClubCountForLocationResponse } from '../models/get-club-count-for-location-response';
import { GetClubListFilteredRequest } from '../models/get-club-list-filtered-request';
import { GetClubListResponse } from '../models/get-club-list-response';
import { GetCountryLocationCountResponse } from '../models/get-country-location-count-response';
import { GetCurrentClubFilterRequest } from '../models/get-current-club-filter-request';
import { GetCurrentClubFilterResponse } from '../models/get-current-club-filter-response';
import { GetGenderFilterResponse } from '../models/get-gender-filter-response';
import { GetLocationFilterResponse } from '../models/get-location-filter-response';
import { GetMusicClubCountResponse } from '../models/get-music-club-count-response';
import { GetMusicFilterResponse } from '../models/get-music-filter-response';
import { GetNamesRequest } from '../models/get-names-request';
import { GetNamesResponse } from '../models/get-names-response';
import { GetPriceFilterResponse } from '../models/get-price-filter-response';

@Injectable({
  providedIn: 'root',
})
export class ClubService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiClubPost
   */
  static readonly ApiClubPostPath = '/api/club';

  /**
   * <seealso cref="M:ClubmappServiceLayer.Managers.ClubManager.GetClubList" />.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiClubPost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiClubPost$Plain$Response(params?: {
  }): Observable<StrictHttpResponse<GetClubListResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.ApiClubPostPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetClubListResponse>;
      })
    );
  }

  /**
   * <seealso cref="M:ClubmappServiceLayer.Managers.ClubManager.GetClubList" />.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiClubPost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiClubPost$Plain(params?: {
  }): Observable<GetClubListResponse> {

    return this.apiClubPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<GetClubListResponse>) => r.body as GetClubListResponse)
    );
  }

  /**
   * <seealso cref="M:ClubmappServiceLayer.Managers.ClubManager.GetClubList" />.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiClubPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiClubPost$Json$Response(params?: {
  }): Observable<StrictHttpResponse<GetClubListResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.ApiClubPostPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetClubListResponse>;
      })
    );
  }

  /**
   * <seealso cref="M:ClubmappServiceLayer.Managers.ClubManager.GetClubList" />.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiClubPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiClubPost$Json(params?: {
  }): Observable<GetClubListResponse> {

    return this.apiClubPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<GetClubListResponse>) => r.body as GetClubListResponse)
    );
  }

  /**
   * Path part for operation apiClubFilteredPost
   */
  static readonly ApiClubFilteredPostPath = '/api/club/filtered';

  /**
   * <seealso cref="M:ClubmappServiceLayer.Managers.ClubManager.GetClubListFiltered(ClubmappSharedModel.RequestModel.ClubRequests.GetClubListFilteredRequest)" />.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiClubFilteredPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiClubFilteredPost$Plain$Response(params?: {
    body?: GetClubListFilteredRequest
  }): Observable<StrictHttpResponse<GetClubListResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.ApiClubFilteredPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetClubListResponse>;
      })
    );
  }

  /**
   * <seealso cref="M:ClubmappServiceLayer.Managers.ClubManager.GetClubListFiltered(ClubmappSharedModel.RequestModel.ClubRequests.GetClubListFilteredRequest)" />.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiClubFilteredPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiClubFilteredPost$Plain(params?: {
    body?: GetClubListFilteredRequest
  }): Observable<GetClubListResponse> {

    return this.apiClubFilteredPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<GetClubListResponse>) => r.body as GetClubListResponse)
    );
  }

  /**
   * <seealso cref="M:ClubmappServiceLayer.Managers.ClubManager.GetClubListFiltered(ClubmappSharedModel.RequestModel.ClubRequests.GetClubListFilteredRequest)" />.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiClubFilteredPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiClubFilteredPost$Json$Response(params?: {
    body?: GetClubListFilteredRequest
  }): Observable<StrictHttpResponse<GetClubListResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.ApiClubFilteredPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetClubListResponse>;
      })
    );
  }

  /**
   * <seealso cref="M:ClubmappServiceLayer.Managers.ClubManager.GetClubListFiltered(ClubmappSharedModel.RequestModel.ClubRequests.GetClubListFilteredRequest)" />.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiClubFilteredPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiClubFilteredPost$Json(params?: {
    body?: GetClubListFilteredRequest
  }): Observable<GetClubListResponse> {

    return this.apiClubFilteredPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<GetClubListResponse>) => r.body as GetClubListResponse)
    );
  }

  /**
   * Path part for operation apiClubFilterCountPost
   */
  static readonly ApiClubFilterCountPostPath = '/api/club/filter-count';

  /**
   * <seealso cref="M:ClubmappServiceLayer.Managers.ClubManager.GetClubCountForFilter(ClubmappSharedModel.RequestModel.ClubRequests.GetClubListFilteredRequest)" />.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiClubFilterCountPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiClubFilterCountPost$Plain$Response(params?: {
    body?: GetClubListFilteredRequest
  }): Observable<StrictHttpResponse<GetClubCountForFilterResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.ApiClubFilterCountPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetClubCountForFilterResponse>;
      })
    );
  }

  /**
   * <seealso cref="M:ClubmappServiceLayer.Managers.ClubManager.GetClubCountForFilter(ClubmappSharedModel.RequestModel.ClubRequests.GetClubListFilteredRequest)" />.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiClubFilterCountPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiClubFilterCountPost$Plain(params?: {
    body?: GetClubListFilteredRequest
  }): Observable<GetClubCountForFilterResponse> {

    return this.apiClubFilterCountPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<GetClubCountForFilterResponse>) => r.body as GetClubCountForFilterResponse)
    );
  }

  /**
   * <seealso cref="M:ClubmappServiceLayer.Managers.ClubManager.GetClubCountForFilter(ClubmappSharedModel.RequestModel.ClubRequests.GetClubListFilteredRequest)" />.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiClubFilterCountPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiClubFilterCountPost$Json$Response(params?: {
    body?: GetClubListFilteredRequest
  }): Observable<StrictHttpResponse<GetClubCountForFilterResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.ApiClubFilterCountPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetClubCountForFilterResponse>;
      })
    );
  }

  /**
   * <seealso cref="M:ClubmappServiceLayer.Managers.ClubManager.GetClubCountForFilter(ClubmappSharedModel.RequestModel.ClubRequests.GetClubListFilteredRequest)" />.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiClubFilterCountPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiClubFilterCountPost$Json(params?: {
    body?: GetClubListFilteredRequest
  }): Observable<GetClubCountForFilterResponse> {

    return this.apiClubFilterCountPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<GetClubCountForFilterResponse>) => r.body as GetClubCountForFilterResponse)
    );
  }

  /**
   * Path part for operation apiClubFilterBasicResultPost
   */
  static readonly ApiClubFilterBasicResultPostPath = '/api/club/filter/basic-result';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiClubFilterBasicResultPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiClubFilterBasicResultPost$Plain$Response(params?: {
    body?: GetClubListFilteredRequest
  }): Observable<StrictHttpResponse<GetBasicClubInfoForFilterResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.ApiClubFilterBasicResultPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetBasicClubInfoForFilterResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiClubFilterBasicResultPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiClubFilterBasicResultPost$Plain(params?: {
    body?: GetClubListFilteredRequest
  }): Observable<GetBasicClubInfoForFilterResponse> {

    return this.apiClubFilterBasicResultPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<GetBasicClubInfoForFilterResponse>) => r.body as GetBasicClubInfoForFilterResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiClubFilterBasicResultPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiClubFilterBasicResultPost$Json$Response(params?: {
    body?: GetClubListFilteredRequest
  }): Observable<StrictHttpResponse<GetBasicClubInfoForFilterResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.ApiClubFilterBasicResultPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetBasicClubInfoForFilterResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiClubFilterBasicResultPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiClubFilterBasicResultPost$Json(params?: {
    body?: GetClubListFilteredRequest
  }): Observable<GetBasicClubInfoForFilterResponse> {

    return this.apiClubFilterBasicResultPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<GetBasicClubInfoForFilterResponse>) => r.body as GetBasicClubInfoForFilterResponse)
    );
  }

  /**
   * Path part for operation apiClubFilterInfoLocationPost
   */
  static readonly ApiClubFilterInfoLocationPostPath = '/api/club/filter-info/location';

  /**
   * <seealso cref="M:ClubmappServiceLayer.Managers.ClubManager.GetLocationFilter" />.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiClubFilterInfoLocationPost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiClubFilterInfoLocationPost$Plain$Response(params?: {
  }): Observable<StrictHttpResponse<GetLocationFilterResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.ApiClubFilterInfoLocationPostPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetLocationFilterResponse>;
      })
    );
  }

  /**
   * <seealso cref="M:ClubmappServiceLayer.Managers.ClubManager.GetLocationFilter" />.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiClubFilterInfoLocationPost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiClubFilterInfoLocationPost$Plain(params?: {
  }): Observable<GetLocationFilterResponse> {

    return this.apiClubFilterInfoLocationPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<GetLocationFilterResponse>) => r.body as GetLocationFilterResponse)
    );
  }

  /**
   * <seealso cref="M:ClubmappServiceLayer.Managers.ClubManager.GetLocationFilter" />.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiClubFilterInfoLocationPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiClubFilterInfoLocationPost$Json$Response(params?: {
  }): Observable<StrictHttpResponse<GetLocationFilterResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.ApiClubFilterInfoLocationPostPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetLocationFilterResponse>;
      })
    );
  }

  /**
   * <seealso cref="M:ClubmappServiceLayer.Managers.ClubManager.GetLocationFilter" />.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiClubFilterInfoLocationPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiClubFilterInfoLocationPost$Json(params?: {
  }): Observable<GetLocationFilterResponse> {

    return this.apiClubFilterInfoLocationPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<GetLocationFilterResponse>) => r.body as GetLocationFilterResponse)
    );
  }

  /**
   * Path part for operation apiClubFilterInfoMusicPost
   */
  static readonly ApiClubFilterInfoMusicPostPath = '/api/club/filter-info/music';

  /**
   * <seealso cref="M:ClubmappServiceLayer.Managers.ClubManager.GetMusicFilter" />.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiClubFilterInfoMusicPost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiClubFilterInfoMusicPost$Plain$Response(params?: {
  }): Observable<StrictHttpResponse<GetMusicFilterResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.ApiClubFilterInfoMusicPostPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetMusicFilterResponse>;
      })
    );
  }

  /**
   * <seealso cref="M:ClubmappServiceLayer.Managers.ClubManager.GetMusicFilter" />.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiClubFilterInfoMusicPost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiClubFilterInfoMusicPost$Plain(params?: {
  }): Observable<GetMusicFilterResponse> {

    return this.apiClubFilterInfoMusicPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<GetMusicFilterResponse>) => r.body as GetMusicFilterResponse)
    );
  }

  /**
   * <seealso cref="M:ClubmappServiceLayer.Managers.ClubManager.GetMusicFilter" />.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiClubFilterInfoMusicPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiClubFilterInfoMusicPost$Json$Response(params?: {
  }): Observable<StrictHttpResponse<GetMusicFilterResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.ApiClubFilterInfoMusicPostPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetMusicFilterResponse>;
      })
    );
  }

  /**
   * <seealso cref="M:ClubmappServiceLayer.Managers.ClubManager.GetMusicFilter" />.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiClubFilterInfoMusicPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiClubFilterInfoMusicPost$Json(params?: {
  }): Observable<GetMusicFilterResponse> {

    return this.apiClubFilterInfoMusicPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<GetMusicFilterResponse>) => r.body as GetMusicFilterResponse)
    );
  }

  /**
   * Path part for operation apiClubFilterInfoGenderPost
   */
  static readonly ApiClubFilterInfoGenderPostPath = '/api/club/filter-info/gender';

  /**
   * <seealso cref="M:ClubmappServiceLayer.Managers.ClubManager.GetGenderFilter" />.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiClubFilterInfoGenderPost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiClubFilterInfoGenderPost$Plain$Response(params?: {
  }): Observable<StrictHttpResponse<GetGenderFilterResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.ApiClubFilterInfoGenderPostPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetGenderFilterResponse>;
      })
    );
  }

  /**
   * <seealso cref="M:ClubmappServiceLayer.Managers.ClubManager.GetGenderFilter" />.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiClubFilterInfoGenderPost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiClubFilterInfoGenderPost$Plain(params?: {
  }): Observable<GetGenderFilterResponse> {

    return this.apiClubFilterInfoGenderPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<GetGenderFilterResponse>) => r.body as GetGenderFilterResponse)
    );
  }

  /**
   * <seealso cref="M:ClubmappServiceLayer.Managers.ClubManager.GetGenderFilter" />.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiClubFilterInfoGenderPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiClubFilterInfoGenderPost$Json$Response(params?: {
  }): Observable<StrictHttpResponse<GetGenderFilterResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.ApiClubFilterInfoGenderPostPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetGenderFilterResponse>;
      })
    );
  }

  /**
   * <seealso cref="M:ClubmappServiceLayer.Managers.ClubManager.GetGenderFilter" />.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiClubFilterInfoGenderPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiClubFilterInfoGenderPost$Json(params?: {
  }): Observable<GetGenderFilterResponse> {

    return this.apiClubFilterInfoGenderPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<GetGenderFilterResponse>) => r.body as GetGenderFilterResponse)
    );
  }

  /**
   * Path part for operation apiClubFilterInfoAgePost
   */
  static readonly ApiClubFilterInfoAgePostPath = '/api/club/filter-info/age';

  /**
   * <seealso cref="M:ClubmappServiceLayer.Managers.ClubManager.GetAgeFilter" />.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiClubFilterInfoAgePost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiClubFilterInfoAgePost$Plain$Response(params?: {
  }): Observable<StrictHttpResponse<GetAgeFilterResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.ApiClubFilterInfoAgePostPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetAgeFilterResponse>;
      })
    );
  }

  /**
   * <seealso cref="M:ClubmappServiceLayer.Managers.ClubManager.GetAgeFilter" />.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiClubFilterInfoAgePost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiClubFilterInfoAgePost$Plain(params?: {
  }): Observable<GetAgeFilterResponse> {

    return this.apiClubFilterInfoAgePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<GetAgeFilterResponse>) => r.body as GetAgeFilterResponse)
    );
  }

  /**
   * <seealso cref="M:ClubmappServiceLayer.Managers.ClubManager.GetAgeFilter" />.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiClubFilterInfoAgePost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiClubFilterInfoAgePost$Json$Response(params?: {
  }): Observable<StrictHttpResponse<GetAgeFilterResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.ApiClubFilterInfoAgePostPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetAgeFilterResponse>;
      })
    );
  }

  /**
   * <seealso cref="M:ClubmappServiceLayer.Managers.ClubManager.GetAgeFilter" />.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiClubFilterInfoAgePost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiClubFilterInfoAgePost$Json(params?: {
  }): Observable<GetAgeFilterResponse> {

    return this.apiClubFilterInfoAgePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<GetAgeFilterResponse>) => r.body as GetAgeFilterResponse)
    );
  }

  /**
   * Path part for operation apiClubFilterInfoPricePost
   */
  static readonly ApiClubFilterInfoPricePostPath = '/api/club/filter-info/price';

  /**
   * <seealso cref="M:ClubmappServiceLayer.Managers.ClubManager.GetPriceFilter" />.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiClubFilterInfoPricePost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiClubFilterInfoPricePost$Plain$Response(params?: {
  }): Observable<StrictHttpResponse<GetPriceFilterResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.ApiClubFilterInfoPricePostPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetPriceFilterResponse>;
      })
    );
  }

  /**
   * <seealso cref="M:ClubmappServiceLayer.Managers.ClubManager.GetPriceFilter" />.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiClubFilterInfoPricePost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiClubFilterInfoPricePost$Plain(params?: {
  }): Observable<GetPriceFilterResponse> {

    return this.apiClubFilterInfoPricePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<GetPriceFilterResponse>) => r.body as GetPriceFilterResponse)
    );
  }

  /**
   * <seealso cref="M:ClubmappServiceLayer.Managers.ClubManager.GetPriceFilter" />.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiClubFilterInfoPricePost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiClubFilterInfoPricePost$Json$Response(params?: {
  }): Observable<StrictHttpResponse<GetPriceFilterResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.ApiClubFilterInfoPricePostPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetPriceFilterResponse>;
      })
    );
  }

  /**
   * <seealso cref="M:ClubmappServiceLayer.Managers.ClubManager.GetPriceFilter" />.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiClubFilterInfoPricePost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiClubFilterInfoPricePost$Json(params?: {
  }): Observable<GetPriceFilterResponse> {

    return this.apiClubFilterInfoPricePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<GetPriceFilterResponse>) => r.body as GetPriceFilterResponse)
    );
  }

  /**
   * Path part for operation apiClubFilterInfoClubLocationPost
   */
  static readonly ApiClubFilterInfoClubLocationPostPath = '/api/club/filter-info/club-location';

  /**
   * <seealso cref="M:ClubmappServiceLayer.Managers.ClubManager.GetClubCountForLocation(ClubmappSharedModel.RequestModel.ClubRequests.GetClubCountForLocationRequest)" />.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiClubFilterInfoClubLocationPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiClubFilterInfoClubLocationPost$Plain$Response(params?: {
    body?: GetClubCountForLocationRequest
  }): Observable<StrictHttpResponse<GetClubCountForLocationResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.ApiClubFilterInfoClubLocationPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetClubCountForLocationResponse>;
      })
    );
  }

  /**
   * <seealso cref="M:ClubmappServiceLayer.Managers.ClubManager.GetClubCountForLocation(ClubmappSharedModel.RequestModel.ClubRequests.GetClubCountForLocationRequest)" />.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiClubFilterInfoClubLocationPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiClubFilterInfoClubLocationPost$Plain(params?: {
    body?: GetClubCountForLocationRequest
  }): Observable<GetClubCountForLocationResponse> {

    return this.apiClubFilterInfoClubLocationPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<GetClubCountForLocationResponse>) => r.body as GetClubCountForLocationResponse)
    );
  }

  /**
   * <seealso cref="M:ClubmappServiceLayer.Managers.ClubManager.GetClubCountForLocation(ClubmappSharedModel.RequestModel.ClubRequests.GetClubCountForLocationRequest)" />.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiClubFilterInfoClubLocationPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiClubFilterInfoClubLocationPost$Json$Response(params?: {
    body?: GetClubCountForLocationRequest
  }): Observable<StrictHttpResponse<GetClubCountForLocationResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.ApiClubFilterInfoClubLocationPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetClubCountForLocationResponse>;
      })
    );
  }

  /**
   * <seealso cref="M:ClubmappServiceLayer.Managers.ClubManager.GetClubCountForLocation(ClubmappSharedModel.RequestModel.ClubRequests.GetClubCountForLocationRequest)" />.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiClubFilterInfoClubLocationPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiClubFilterInfoClubLocationPost$Json(params?: {
    body?: GetClubCountForLocationRequest
  }): Observable<GetClubCountForLocationResponse> {

    return this.apiClubFilterInfoClubLocationPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<GetClubCountForLocationResponse>) => r.body as GetClubCountForLocationResponse)
    );
  }

  /**
   * Path part for operation apiClubFilterInfoLocationCountryPost
   */
  static readonly ApiClubFilterInfoLocationCountryPostPath = '/api/club/filter-info/location-country';

  /**
   * <seealso cref="M:ClubmappServiceLayer.Managers.ClubManager.GetCountryLocationCount" />.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiClubFilterInfoLocationCountryPost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiClubFilterInfoLocationCountryPost$Plain$Response(params?: {
  }): Observable<StrictHttpResponse<GetCountryLocationCountResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.ApiClubFilterInfoLocationCountryPostPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetCountryLocationCountResponse>;
      })
    );
  }

  /**
   * <seealso cref="M:ClubmappServiceLayer.Managers.ClubManager.GetCountryLocationCount" />.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiClubFilterInfoLocationCountryPost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiClubFilterInfoLocationCountryPost$Plain(params?: {
  }): Observable<GetCountryLocationCountResponse> {

    return this.apiClubFilterInfoLocationCountryPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<GetCountryLocationCountResponse>) => r.body as GetCountryLocationCountResponse)
    );
  }

  /**
   * <seealso cref="M:ClubmappServiceLayer.Managers.ClubManager.GetCountryLocationCount" />.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiClubFilterInfoLocationCountryPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiClubFilterInfoLocationCountryPost$Json$Response(params?: {
  }): Observable<StrictHttpResponse<GetCountryLocationCountResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.ApiClubFilterInfoLocationCountryPostPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetCountryLocationCountResponse>;
      })
    );
  }

  /**
   * <seealso cref="M:ClubmappServiceLayer.Managers.ClubManager.GetCountryLocationCount" />.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiClubFilterInfoLocationCountryPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiClubFilterInfoLocationCountryPost$Json(params?: {
  }): Observable<GetCountryLocationCountResponse> {

    return this.apiClubFilterInfoLocationCountryPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<GetCountryLocationCountResponse>) => r.body as GetCountryLocationCountResponse)
    );
  }

  /**
   * Path part for operation apiClubCurrentFilterPost
   */
  static readonly ApiClubCurrentFilterPostPath = '/api/club/current-filter';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiClubCurrentFilterPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiClubCurrentFilterPost$Plain$Response(params?: {
    body?: GetCurrentClubFilterRequest
  }): Observable<StrictHttpResponse<GetCurrentClubFilterResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.ApiClubCurrentFilterPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetCurrentClubFilterResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiClubCurrentFilterPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiClubCurrentFilterPost$Plain(params?: {
    body?: GetCurrentClubFilterRequest
  }): Observable<GetCurrentClubFilterResponse> {

    return this.apiClubCurrentFilterPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<GetCurrentClubFilterResponse>) => r.body as GetCurrentClubFilterResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiClubCurrentFilterPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiClubCurrentFilterPost$Json$Response(params?: {
    body?: GetCurrentClubFilterRequest
  }): Observable<StrictHttpResponse<GetCurrentClubFilterResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.ApiClubCurrentFilterPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetCurrentClubFilterResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiClubCurrentFilterPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiClubCurrentFilterPost$Json(params?: {
    body?: GetCurrentClubFilterRequest
  }): Observable<GetCurrentClubFilterResponse> {

    return this.apiClubCurrentFilterPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<GetCurrentClubFilterResponse>) => r.body as GetCurrentClubFilterResponse)
    );
  }

  /**
   * Path part for operation apiClubFilterInfoClubMusicPost
   */
  static readonly ApiClubFilterInfoClubMusicPostPath = '/api/club/filter-info/club-music';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiClubFilterInfoClubMusicPost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiClubFilterInfoClubMusicPost$Plain$Response(params?: {
  }): Observable<StrictHttpResponse<GetMusicClubCountResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.ApiClubFilterInfoClubMusicPostPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetMusicClubCountResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiClubFilterInfoClubMusicPost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiClubFilterInfoClubMusicPost$Plain(params?: {
  }): Observable<GetMusicClubCountResponse> {

    return this.apiClubFilterInfoClubMusicPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<GetMusicClubCountResponse>) => r.body as GetMusicClubCountResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiClubFilterInfoClubMusicPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiClubFilterInfoClubMusicPost$Json$Response(params?: {
  }): Observable<StrictHttpResponse<GetMusicClubCountResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.ApiClubFilterInfoClubMusicPostPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetMusicClubCountResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiClubFilterInfoClubMusicPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiClubFilterInfoClubMusicPost$Json(params?: {
  }): Observable<GetMusicClubCountResponse> {

    return this.apiClubFilterInfoClubMusicPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<GetMusicClubCountResponse>) => r.body as GetMusicClubCountResponse)
    );
  }

  /**
   * Path part for operation apiClubFilterInfoLocationsByIdsPost
   */
  static readonly ApiClubFilterInfoLocationsByIdsPostPath = '/api/club/filter-info/locations-by-ids';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiClubFilterInfoLocationsByIdsPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiClubFilterInfoLocationsByIdsPost$Plain$Response(params?: {
    body?: GetNamesRequest
  }): Observable<StrictHttpResponse<GetNamesResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.ApiClubFilterInfoLocationsByIdsPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetNamesResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiClubFilterInfoLocationsByIdsPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiClubFilterInfoLocationsByIdsPost$Plain(params?: {
    body?: GetNamesRequest
  }): Observable<GetNamesResponse> {

    return this.apiClubFilterInfoLocationsByIdsPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<GetNamesResponse>) => r.body as GetNamesResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiClubFilterInfoLocationsByIdsPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiClubFilterInfoLocationsByIdsPost$Json$Response(params?: {
    body?: GetNamesRequest
  }): Observable<StrictHttpResponse<GetNamesResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.ApiClubFilterInfoLocationsByIdsPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetNamesResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiClubFilterInfoLocationsByIdsPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiClubFilterInfoLocationsByIdsPost$Json(params?: {
    body?: GetNamesRequest
  }): Observable<GetNamesResponse> {

    return this.apiClubFilterInfoLocationsByIdsPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<GetNamesResponse>) => r.body as GetNamesResponse)
    );
  }

  /**
   * Path part for operation apiClubFilterInfoMusicByIdsPost
   */
  static readonly ApiClubFilterInfoMusicByIdsPostPath = '/api/club/filter-info/music-by-ids';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiClubFilterInfoMusicByIdsPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiClubFilterInfoMusicByIdsPost$Plain$Response(params?: {
    body?: GetNamesRequest
  }): Observable<StrictHttpResponse<GetNamesResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.ApiClubFilterInfoMusicByIdsPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetNamesResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiClubFilterInfoMusicByIdsPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiClubFilterInfoMusicByIdsPost$Plain(params?: {
    body?: GetNamesRequest
  }): Observable<GetNamesResponse> {

    return this.apiClubFilterInfoMusicByIdsPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<GetNamesResponse>) => r.body as GetNamesResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiClubFilterInfoMusicByIdsPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiClubFilterInfoMusicByIdsPost$Json$Response(params?: {
    body?: GetNamesRequest
  }): Observable<StrictHttpResponse<GetNamesResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ClubService.ApiClubFilterInfoMusicByIdsPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetNamesResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiClubFilterInfoMusicByIdsPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiClubFilterInfoMusicByIdsPost$Json(params?: {
    body?: GetNamesRequest
  }): Observable<GetNamesResponse> {

    return this.apiClubFilterInfoMusicByIdsPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<GetNamesResponse>) => r.body as GetNamesResponse)
    );
  }

}
