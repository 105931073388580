<div
  (click)="goToLocation(data)"
  class="
    w-full
    bg-white
    border-2 border-gray-300
    p-5
    rounded-md
    tracking-wide
    shadow-lg
    cursor-pointer
    mb-10
  "
>
  <div id="header" class="flex">
    <div
      *ngIf="backgroundImage"
      class="cover-image block rounded-md border-2 border-gray-300"
      [ngStyle]="{'background-image': backgroundImage}"
    ></div>
    <div id="body" class="flex flex-col ml-5">
      <h4 id="name" class="mt-0 text-xl font-semibold mb-2">{{ data.name }}</h4>
      <p id="job" class="text-gray-800 mt-2">
        {{ data.name }}<br />
        Musik: {{ data.musicGenres.join(', ') }}
        <!-- | truncate : 400-->
      </p>
      <div class="flex mt-3 justify-between">
        <div class="flex items-center flex-column">
<!--          <div-->
<!--            class="-->
<!--              date-item-->
<!--              inline-block-->
<!--              rounded-full-->
<!--              text-gray-600-->
<!--              bg-gray-100-->
<!--              px-2-->
<!--              py-1-->
<!--              text-xs-->
<!--              font-bold-->
<!--              mr-3-->
<!--            "-->
<!--          >-->
<!--            <span>{{ data.start | date:'short'}}</span>-->
<!--            &lt;!&ndash;<small>{{ data.start | date:'short' }}</small>&ndash;&gt;-->
<!--          </div>-->
<!--          <div-->
<!--            *ngIf="data.end"-->
<!--            class="-->
<!--              date-item-->
<!--              inline-block-->
<!--              rounded-full-->
<!--              text-gray-600-->
<!--              bg-gray-100-->
<!--              px-2-->
<!--              py-1-->
<!--              text-xs-->
<!--              font-bold-->
<!--              mr-3-->
<!--            "-->
<!--          >-->
<!--            <small *ngIf="functions.checkMoreThanOneDay(data)"-->
<!--              >{{ data.end | date:'short' }}</small-->
<!--            >-->
<!--            {{ data.end | date:'short' }}-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="flex items-end">-->
<!--          <ion-button-->
<!--            fill="clear"-->
<!--            *ngIf="data.facebook_url"-->
<!--            (click)="functions.goToPage(data.facebook_url)"-->
<!--          >-->
<!--            <ion-icon name="logo-facebook"></ion-icon>-->
<!--          </ion-button>-->
<!--          <ion-button-->
<!--            fill="clear"-->
<!--            *ngIf="data.start"-->
<!--            [tooltip]="data.start| date:'short' "-->
<!--          >-->
<!--            <ion-icon name="time"></ion-icon>-->
<!--          </ion-button>-->
          <ion-button
            fill="clear"
            *ngIf="data.location"
            (click)="functions.goToPage('https://maps.google.com/?q=' + functions.encodeURIComponent(data.name) + ' ' + functions.encodeURIComponent(data.location))"
          >
            <ion-icon name="navigate"></ion-icon>
          </ion-button>
        </div>
      </div>
    </div>
  </div>
</div>
<!--
<li *ngIf="data">
  <span></span>

  <div (click)="goToLocation(data)">
    <div>
      <div
        class="cover-image"
        [ngStyle]="{'background-image': 'url(' + data?.picture_350x350 + ')'}"
      >
      </div>
    </div>
    <div class="content">
      <div class="title">{{data.title}}</div>
      <div class="info">{{ data.description | truncate : 400 }}</div>
    </div>
  </div>

  <span class="number">
    <div class="date-item">
      <span>{{ data.start | date:'short'}}</span>
      <small>{{ data.start | date:'short' }}</small>
    </div>
    <div class="date-item">
      <small *ngIf="functions.checkMoreThanOneDay(data)"
        >{{ data.end | date:'short' }}</small
      >
      {{ data.end | date:'short' }}
    </div>
  </span>

  <div class="bottom-info">
    <div class="date-item" margin-top>
      {{ data.start | date:'short' }}
      <div>{{ data.start | date:'short' }}</div>
    </div>
    <div class="date-item" margin-top>
      {{ data.end | date:'short' }}
      <div *ngIf="functions.checkMoreThanOneDay(data)">
        {{ data.end | date:'short' }}
      </div>
    </div>
    <div class="flex items-end">
      <ion-button
        *ngIf="data.facebook_url"
        (click)="functions.goToPage(data.facebook_url)"
      >
        <ion-icon name="logo-facebook"></ion-icon>
      </ion-button>
      <ion-button *ngIf="data.start" [tooltip]="data.start ">
        <ion-icon name="time"></ion-icon>
      </ion-button>
      <ion-button
        *ngIf="data.location"
        (click)="functions.goToPage('https://maps.google.com/?q=' + data.location)"
      >
        <ion-icon name="navigate"></ion-icon>
      </ion-button>
    </div>
  </div>
</li>
-->
