import {AutoCompleteService} from 'ionic4-auto-complete';
import {Injectable} from "@angular/core";
import 'rxjs/add/operator/map'
import {Observable, of} from "rxjs";
import {GetLocationFilterResponse} from "../../api/models/get-location-filter-response";
import {ClubService} from "../../api/services/club.service";
import {map, tap} from "rxjs/operators";

@Injectable()
export class SearchService implements AutoCompleteService {
  formValueAttribute = '';
  private countries: GetLocationFilterResponse;

  constructor(private clubService: ClubService) {

  }

  getResults(keyword:string) {
    let observable: Observable<GetLocationFilterResponse>;

    if (!this.countries?.locationFilterEntries?.length) {
      observable = this.clubService.apiClubFilterInfoLocationPost$Json().pipe(tap(countries => this.countries = countries));
    } else {
      observable = of(this.countries);
    }

    return observable.pipe(
      map(
        (result) => {
          return result.locationFilterEntries.filter(
            (item) => {
              return item.location.toLowerCase().startsWith(keyword.toLowerCase())
                || item.country.toLowerCase().startsWith(keyword.toLowerCase());
            }
          );
        }
      )
    );
  }
}
