import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Functions } from '@common/services/providers';
import { NavController } from '@ionic/angular';

import PlaceResult = google.maps.places.PlaceResult;
import {ProfileService} from "../../api/services/profile.service";
import {GetClubProfileResponse} from "../../api/models/get-club-profile-response";
import {ImagePipe} from "@common/pipes/image/image";

@Component({
  selector: 'cm-location',
  templateUrl: './location.page.html',
  styleUrls: ['./location.page.scss'],
})
export class LocationPage {
  club: GetClubProfileResponse;

  googleLocationUrl: string;
  vibrantColor: string = '#0082b7';

  moreThanOneDay: boolean;

  placeInformation: PlaceResult[];

  placeImage: string;
  loading: boolean;

  constructor(
    public navCtrl: NavController,
    public titleService: Title,
    public metaService: Meta,
    public functions: Functions,
    public http: HttpClient,
    private route: ActivatedRoute,
    private profileService: ProfileService,
    private imagePipe: ImagePipe
  ) {}

  async ngOnInit() {
    await this.loadLocations();
  }

  async loadLocations() {
    this.loading = true;
    const locationId = this.route.snapshot.paramMap.get('location')?.split('-')[0];
    if (locationId) {
      await Promise.all([
        new Promise(resolve => {
          this.profileService.apiProfileClubPost$Json({ body: {
              clubId: +locationId,
              culture: navigator.language,
              currentTime: (new Date()).toJSON()
          } }).toPromise().then(c => {
            this.club = c;
            resolve(null);
          });
        }),
      ]);
      this.setSeo();
      // this._backgroundImage = await this.imagePipe.transform(this.club.locationLogo).toPromise();
    } else {
      this.navCtrl.navigateForward(['']);
    }
    this.loading = false;
  }

  // async ngOnInit() {
    // const id = this.route.snapshot.params.id;
    // const result: any = await this.api.get('locations/' + id).toPromise();
    // console.log('result', result);
    // this.setSeo(result);
    // // this.getPlaceImage(result);
    //
    // this.functions.getPlaceInformation(result).then((res: PlaceResult[]) => {
    //   this.placeInformation = res;
    //   this.placeImage = res[0].photos[0].getUrl({ maxHeight: 300 });
    //   console.log(this.placeInformation);
    // });
    //
    // this.moreThanOneDay = this.functions.checkMoreThanOneDay(result);
    //
    // // TODO fix
    // /*this.functions.getColorPalette(result.picture_350x350).then(
    //   (palette) => {
    //     if (palette.Vibrant != null) {
    //       this.vibrantColor = palette.Vibrant.getHex();
    //     }
    //   },
    //   (err) => {
    //     console.error('Cannot generate Vibrant Colors of ' + err);
    //   }
    // );*/
    //
    // this.googleLocationUrl =
    //   'https://maps.googleapis.com/maps/api/staticmap?center=' +
    //   result.location +
    //   '&zoom=13&size=600x300&key=AIzaSyBDXaa-r52dwCEcNw2WHxTn9A6ZfG5vgJs';
    // this.location = result;
  // }

  setSeo(): void {
    const data = this.club;
    const description = data.aboutUs.substring(0, 320);
    this.titleService.setTitle('Clubmapp - ' + data.clubName);
    this.metaService.updateTag({ name: 'description', content: description });
    /* Add Service to get Keywords from description with an AI */
    this.metaService.updateTag({ name: 'keywords', content: 'location, locations' });
    this.metaService.updateTag({ name: 'robots', content: 'noindex, follow' });
    if (data.clubLogo) {
      this.imagePipe.transform(data.clubLogo).toPromise().then(logo => this.metaService.addTag(
        { name: 'og:image', content: (logo as any)?.changingThisBreaksApplicationSecurity },
        true
      ));
    }
    this.metaService.addTag(
      { name: 'og:description', content: description },
      true
    );
  }
}
