/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { GetClubProfileRequest } from '../models/get-club-profile-request';
import { GetClubProfileResponse } from '../models/get-club-profile-response';
import { GetCountryProfileRequest } from '../models/get-country-profile-request';
import { GetCountryProfileResponse } from '../models/get-country-profile-response';
import { GetLocationInfoForCountryRequest } from '../models/get-location-info-for-country-request';
import { GetLocationInfoForCountryResponse } from '../models/get-location-info-for-country-response';
import { GetLocationProfileRequest } from '../models/get-location-profile-request';
import { GetLocationProfileResponse } from '../models/get-location-profile-response';

@Injectable({
  providedIn: 'root',
})
export class ProfileService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiProfileClubPost
   */
  static readonly ApiProfileClubPostPath = '/api/profile/club';

  /**
   * <seealso cref="M:ClubmappServiceLayer.Managers.ProfileManager.GetClubProfile(ClubmappSharedModel.RequestModel.ProfileRequests.GetClubProfileRequest)" />.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProfileClubPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProfileClubPost$Plain$Response(params?: {
    body?: GetClubProfileRequest
  }): Observable<StrictHttpResponse<GetClubProfileResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProfileService.ApiProfileClubPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetClubProfileResponse>;
      })
    );
  }

  /**
   * <seealso cref="M:ClubmappServiceLayer.Managers.ProfileManager.GetClubProfile(ClubmappSharedModel.RequestModel.ProfileRequests.GetClubProfileRequest)" />.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProfileClubPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProfileClubPost$Plain(params?: {
    body?: GetClubProfileRequest
  }): Observable<GetClubProfileResponse> {

    return this.apiProfileClubPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<GetClubProfileResponse>) => r.body as GetClubProfileResponse)
    );
  }

  /**
   * <seealso cref="M:ClubmappServiceLayer.Managers.ProfileManager.GetClubProfile(ClubmappSharedModel.RequestModel.ProfileRequests.GetClubProfileRequest)" />.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProfileClubPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProfileClubPost$Json$Response(params?: {
    body?: GetClubProfileRequest
  }): Observable<StrictHttpResponse<GetClubProfileResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProfileService.ApiProfileClubPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetClubProfileResponse>;
      })
    );
  }

  /**
   * <seealso cref="M:ClubmappServiceLayer.Managers.ProfileManager.GetClubProfile(ClubmappSharedModel.RequestModel.ProfileRequests.GetClubProfileRequest)" />.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProfileClubPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProfileClubPost$Json(params?: {
    body?: GetClubProfileRequest
  }): Observable<GetClubProfileResponse> {

    return this.apiProfileClubPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<GetClubProfileResponse>) => r.body as GetClubProfileResponse)
    );
  }

  /**
   * Path part for operation apiProfileLocationPost
   */
  static readonly ApiProfileLocationPostPath = '/api/profile/location';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProfileLocationPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProfileLocationPost$Plain$Response(params?: {
    body?: GetLocationProfileRequest
  }): Observable<StrictHttpResponse<GetLocationProfileResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProfileService.ApiProfileLocationPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetLocationProfileResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProfileLocationPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProfileLocationPost$Plain(params?: {
    body?: GetLocationProfileRequest
  }): Observable<GetLocationProfileResponse> {

    return this.apiProfileLocationPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<GetLocationProfileResponse>) => r.body as GetLocationProfileResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProfileLocationPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProfileLocationPost$Json$Response(params?: {
    body?: GetLocationProfileRequest
  }): Observable<StrictHttpResponse<GetLocationProfileResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProfileService.ApiProfileLocationPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetLocationProfileResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProfileLocationPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProfileLocationPost$Json(params?: {
    body?: GetLocationProfileRequest
  }): Observable<GetLocationProfileResponse> {

    return this.apiProfileLocationPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<GetLocationProfileResponse>) => r.body as GetLocationProfileResponse)
    );
  }

  /**
   * Path part for operation apiProfileCountryPost
   */
  static readonly ApiProfileCountryPostPath = '/api/profile/country';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProfileCountryPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProfileCountryPost$Plain$Response(params?: {
    body?: GetCountryProfileRequest
  }): Observable<StrictHttpResponse<GetCountryProfileResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProfileService.ApiProfileCountryPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetCountryProfileResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProfileCountryPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProfileCountryPost$Plain(params?: {
    body?: GetCountryProfileRequest
  }): Observable<GetCountryProfileResponse> {

    return this.apiProfileCountryPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<GetCountryProfileResponse>) => r.body as GetCountryProfileResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProfileCountryPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProfileCountryPost$Json$Response(params?: {
    body?: GetCountryProfileRequest
  }): Observable<StrictHttpResponse<GetCountryProfileResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProfileService.ApiProfileCountryPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetCountryProfileResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProfileCountryPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProfileCountryPost$Json(params?: {
    body?: GetCountryProfileRequest
  }): Observable<GetCountryProfileResponse> {

    return this.apiProfileCountryPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<GetCountryProfileResponse>) => r.body as GetCountryProfileResponse)
    );
  }

  /**
   * Path part for operation apiProfileCountryLocationsPost
   */
  static readonly ApiProfileCountryLocationsPostPath = '/api/profile/country-locations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProfileCountryLocationsPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProfileCountryLocationsPost$Plain$Response(params?: {
    body?: GetLocationInfoForCountryRequest
  }): Observable<StrictHttpResponse<GetLocationInfoForCountryResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProfileService.ApiProfileCountryLocationsPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetLocationInfoForCountryResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProfileCountryLocationsPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProfileCountryLocationsPost$Plain(params?: {
    body?: GetLocationInfoForCountryRequest
  }): Observable<GetLocationInfoForCountryResponse> {

    return this.apiProfileCountryLocationsPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<GetLocationInfoForCountryResponse>) => r.body as GetLocationInfoForCountryResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiProfileCountryLocationsPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProfileCountryLocationsPost$Json$Response(params?: {
    body?: GetLocationInfoForCountryRequest
  }): Observable<StrictHttpResponse<GetLocationInfoForCountryResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProfileService.ApiProfileCountryLocationsPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetLocationInfoForCountryResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiProfileCountryLocationsPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiProfileCountryLocationsPost$Json(params?: {
    body?: GetLocationInfoForCountryRequest
  }): Observable<GetLocationInfoForCountryResponse> {

    return this.apiProfileCountryLocationsPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<GetLocationInfoForCountryResponse>) => r.body as GetLocationInfoForCountryResponse)
    );
  }

}
