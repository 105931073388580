<ion-content>
  <header
    *ngIf="backgroundImage"
    mode="small"
    title="Clubs"
    [subtitle]="location?.locationName || 'In deiner Nähe'"
    [backgroundimage]="backgroundImage"
  ></header>
  <header
    *ngIf="!backgroundImage"
    mode="small"
    title="Clubs"
    [subtitle]="location?.locationName || 'In deiner Nähe'"
  ></header>
  <main class="container mx-auto p-5">
    <div class="flex items-center justify-center w-full py-10" *ngIf="loading">
      <ion-spinner></ion-spinner>
    </div>
    <div
      class="
        m-10
        text-sm text-left text-gray-600
        bg-gray-200
        border border-gray-400
        h-12
        flex
        items-center
        p-4
        rounded-sm
      "
      *ngIf="!loading && locations?.length == 0"
    >
      Keine Clubs vorhanden!
    </div>

    <div *ngIf="!loading">
      <div class="timeline mt-5">
        <ul>
          <ion-list>
            <location-list-entry
              *ngFor="let club of locations"
              [data]="club"
            ></location-list-entry>
            <ion-infinite-scroll
              *ngIf="!loadedAllLocations && locations?.length != 0"
              (ionInfinite)="doInfinite($event)"
            >
              <ion-infinite-scroll-content></ion-infinite-scroll-content>
            </ion-infinite-scroll>
          </ion-list>
        </ul>
      </div>
    </div>
  </main>

  <footer></footer>
</ion-content>
