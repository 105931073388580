import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'cm-new-event',
  templateUrl: './new-event.page.html',
  styleUrls: ['./new-event.page.scss'],
})
export class NewEventPage implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
