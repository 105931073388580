import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NavController } from '@ionic/angular';
import {SearchService} from "@common/services/search.service";

@Component({
  selector: 'search',
  templateUrl: 'search.html',
  styleUrls: ['./search.scss'],
})
export class SearchComponent implements OnInit {
  searchform: FormGroup;

  constructor(public navCtrl: NavController, public searchService: SearchService) {}

  ngOnInit(): void {
    this.searchform = new FormGroup({
      searchField: new FormControl('', [Validators.required]),
    });
  }

  onSelect(event) {
    this.navCtrl.navigateForward(['locations', `${event.id}-${event.location}`]);
  }

  submit(): void {
    //@Todo Start search on second Page
    const selectedElement = this.searchform.value.searchField;
    alert(selectedElement.title);
  }
}
