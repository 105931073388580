import { Component, Input } from '@angular/core';
import { Functions } from '@common/services/providers';
import {GetClubProfileResponse} from "../../../../api/models/get-club-profile-response";
import {SafeUrl} from "@angular/platform-browser";
import {ImagePipe} from "@common/pipes/image/image";

@Component({
  selector: 'location-header',
  templateUrl: 'location-header.html',
  styleUrls: ['./location-header.scss'],
})
export class LocationHeaderComponent {
  @Input() club: GetClubProfileResponse;
  private _backgroundImage: SafeUrl;

  constructor(public functions: Functions,
    private imagePipe: ImagePipe) {}

  public get backgroundImage() {
    return `url(${(this._backgroundImage as any)?.changingThisBreaksApplicationSecurity})`;
  }

  async ngOnInit(): Promise<void> {
    this._backgroundImage = await this.imagePipe.transform(this.club.clubLogo).toPromise();
  }
}
