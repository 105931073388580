import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { google } from 'google-maps';

// eslint-disable-next-line no-var
declare var google: google;
import Vibrant from 'node-vibrant';

@Injectable({
  providedIn: 'root',
})
export class Functions {
  constructor(public http: HttpClient) {}

  getPlaceInformation(data: any) {
    return new Promise((resolve, reject) => {
      const service = new google.maps.places.PlacesService(
        document.createElement('div')
      );

      const callback: any = (e: google.maps.places.PlaceResult[]) => {
        resolve(e);
      };

      service.textSearch({ query: data.location }, callback);
    });
  }

  goToPage(url) {
    const win = window.open(url, '_blank');
    win.focus();
  }

  encodeURIComponent(uriComponent: string | number | boolean): string {
    return encodeURIComponent(uriComponent);
  }

  getColorPalette(image) {
    return Vibrant.from(image).getPalette();
  }

  checkMoreThanOneDay(data) {
    // TODO fix
    const format = 'DD. MMMM YYYY';
    const start = ''; // moment(data.start).format(format);
    const end = ''; //moment(data.end).format(format);
    if (start !== end) {
      return true;
    } else {
      return false;
    }
  }

  commaListToRealList(musicGenres: Array<string>) {

  }
}
