import { Component } from '@angular/core';

@Component({
  selector: 'footer',
  templateUrl: 'footer.html',
  styleUrls: ['./footer.scss'],
})
export class FooterComponent {
  constructor() {}
}
