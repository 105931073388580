<ion-content>
  <header mode="no-cover"></header>
  <ng-container *ngIf="club">
    <location-header [club]="club"></location-header>

    <main class="container mx-auto">
      <div
        class="
          grid
          gap-4
          lg:grid-cols-3
          grid-cols-1
          md:grid-cols-1
          sm:grid-cols-1
        "
      >
        <ion-card
          class="p-5"
          [ngStyle]="{'box-shadow': 'inset 0 3px 0 ' + vibrantColor }"
        >
          <ion-card-title> Informationen </ion-card-title>
          <div class="mt-3">
<!--            <div *ngIf="location.start">-->
<!--              <meta-->
<!--                itemprop="startDate"-->
<!--                [content]="location.start| date:'short' "-->
<!--              />-->
<!--              <strong>Datum</strong><br />-->
<!--              {{location.start| date:'short' }}-->
<!--            </div>-->
<!--            <div *ngIf="location.start" class="mt-3">-->
<!--              <strong>Beginn</strong><br />-->
<!--              {{location.start| date:'short' }} Uhr<br />-->
<!--              <small *ngIf="moreThanOneDay"-->
<!--                >{{location.start| date:'short' }}</small-->
<!--              >-->
<!--            </div>-->
<!--            <div *ngIf="location.end" class="mt-3">-->
<!--              <strong>Ende</strong><br />-->
<!--              {{location.end| date:'short' }} Uhr<br />-->
<!--              <small *ngIf="moreThanOneDay">{{location.end| date:'short' }}</small>-->
<!--            </div>-->

            <div *ngIf="club.location" class="mt-3">
              <strong>Ort</strong><br />
              <!--
              @Todo Add Seo Meta itemprops
              <div class="schema-org" itemprop="address" itemscope="" itemtype="http://schema.org/PostalAddress">
                    <span itemprop="streetAddress"></span>
                    <meta itemprop="addressLocality" content="">
                    <meta itemprop="PostalCode" content="">
              </div>
            -->
              <a
                (click)="functions.goToPage('https://maps.google.com/?q=' + functions.encodeURIComponent(club.clubName) + ' ' + functions.encodeURIComponent(club.location))"
              >
                {{ club.street }}<br />
                {{ club.zipcode }} {{ club.location }}<br />
                {{ club.country }}
              </a>
            </div>

            <div *ngIf="club.email" class="mt-3">
              <strong>E-Mail Adresse</strong><br />
              <a href="mailto:{{ club.email }}">
                {{ club.email }}
              </a>
            </div>

            <div *ngIf="club.phoneNumber" class="mt-3">
              <strong>Telefonnr.</strong><br />
              <a href="tel:{{ club.phoneNumber.replace(' ', '') }}">
                {{ club.phoneNumber }}
              </a>
            </div>
          </div>
          <div
            margin-top
            class="static-map mt-5"
            (click)="functions.goToPage('https://maps.google.com/?q=' + functions.encodeURIComponent(club.clubName) + ' ' + functions.encodeURIComponent(club.location))"
          >
            <ion-icon name="navigate"></ion-icon>
            <img class="border-2" [src]="googleLocationUrl" />
          </div>

          <img
            class="place-img mt-5 border-2"
            margin-top
            *ngIf="placeImage"
            imageViewer
            [src]="placeImage"
          />
        </ion-card>
        <ion-card
          class="p-5 col-span-2"
          [ngStyle]="{'box-shadow': 'inset 0 3px 0 ' + vibrantColor }"
        >
          <ion-card-title> Beschreibung </ion-card-title>
          <ion-card-content
            [innerHTML]="club.aboutUs  | linky "
            class="location-description"
          ></ion-card-content>
        </ion-card>
      </div>
    </main>
  </ng-container>

  <footer></footer>
</ion-content>
