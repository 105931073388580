<nav>
  <div class="header-big">
    <a [routerLink]="['/home']" class="header-logo px-5">
      <img src="assets/img/logo_small.png" alt="Clubmapp" title="Clubmapp" />
      <span>Clubmapp</span>
    </a>

    <div class="header-menu">
      <ion-button *ngIf="backbutton" color="light" style="margin-right: 25px">
        <ion-icon name="arrow-back"></ion-icon>
      </ion-button>
      <div class="header-menu-items-left">
        <div
          class="header-menu-item"
          *ngFor="let item of items"
          (click)="goToPage(item)"
        >
          {{ item.title }}
        </div>
      </div>

      <div class="header-menu-items-right"></div>
    </div>
  </div>
  <div class="header-mobile">
    <div (click)="mobileMenu = !mobileMenu" class="header-mobile-logo">
      <img
        src="assets/img/mobile-logo.png"
        alt="logo"
        width="142"
        height="28"
      />
      <i class="fa fa-chevron-down" aria-hidden="true"></i>
    </div>
    <div *ngIf="mobileMenu" class="header-mobile-menu">
      <ul>
        <li>
          <a href="/"> <i class="fa fa-home" aria-hidden="true"></i>clubmapp</a>
        </li>
        <li class="menu-item-has-children">
          <a href="#">
            <i class="fa fa-map-marker" aria-hidden="true"></i>Places
          </a>
          <ul>
            <li>
              <a href="#">Places</a>
            </li>
            <li>
              <a href="#">Single place</a>
            </li>
          </ul>
        </li>
        <li class="menu-item-has-children">
          <a href="#"> <i class="fa fa-mobile" aria-hidden="true"></i>Apps</a>
          <ul>
            <li>
              <a
                href="https://itunes.apple.com/de/app/clubmapp/id1539899809"
                >iPhone</a
              >
            </li>
            <li>
              <a
                href="https://play.google.com/store/apps/details?id=clubmapp.android&hl=de"
                >Android</a
              >
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</nav>
<div
  *ngIf="mode != 'no-cover'"
  class="fullscreen-image"
  [ngStyle]="{'min-height': mode == 'small' ? '350px' : '', 'background-image': 'url(' + (backgroundimage?.changingThisBreaksApplicationSecurity || backgroundimage) + ')'}"
>
  <div class="container mx-auto title-block">
    <h1>{{title}}</h1>
    <h2>{{subtitle}}</h2>
  </div>
  <div *ngIf="search" class="search-block">
    <div class="search-block--innercontent">
      <ion-grid>
        <ion-row>
          <ion-col>
            <!--<ion-searchbar (ionInput)="getItems($event)" placeholder="Suchen ..."></ion-searchbar> -->

            <search placeholder="Suchen ..."></search>
          </ion-col>
          <!--<ion-col>

            <ion-searchbar (ionInput)="getItems($event)" placeholder="Was suchst du?"></ion-searchbar>
          </ion-col>
          <ion-col>

            <ion-searchbar (ionInput)="getItems($event)" placeholder="Wann?"></ion-searchbar>
          </ion-col>-->
        </ion-row>
      </ion-grid>
    </div>
  </div>
</div>
