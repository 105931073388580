import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'cm-contact',
  templateUrl: './contact.page.html',
  styleUrls: ['./contact.page.scss'],
})
export class ContactPage implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
