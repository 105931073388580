<div class="b-footer">
  <div class="b-footer__info px-5">
    <div
      class="
        container
        mx-auto
        grid
        gap-4
        lg:grid-cols-3
        md:grid-cols-2
        sm:grid-cols-1
        px-5
      "
    >
      <div class="b-footer__text">
        <a href="#" class="b-footer__logo">Night City</a>
        <p>
          Find the hottest clubs in your area. Become part of an exclusive
          nightlife community. Meet and chat with people in the same club.
        </p>
        <div class="b-footer__copyright mt-10">
          © 2021
          <a href="/">Clubmapp GmbH</a>
          <br />
          All rights reserved
        </div>
      </div>
      <div class="b-footer__nav__col">
        <div class="b-footer__nav__col__title">About us</div>
        <ul>
          <li>
            <a [routerLink]="['/imprint']">Impressum</a>
          </li>
        </ul>
      </div>
    </div>
    <div class="flex shared">
      <div class="container mx-auto flex justify-between">
        <div class="flex">
          <a href="https://www.facebook.com/Clubmapp/" class="soc-item">
            <i class="fab fa-facebook" aria-hidden="true"></i>
          </a>
          <a href="https://twitter.com/clubmapp" class="soc-item">
            <i class="fab fa-twitter" aria-hidden="true"></i>
          </a>
          <a href="https://plus.google.com/+Clubmapp" class="soc-item">
            <i class="fab fa-google-plus" aria-hidden="true"></i>
          </a>
        </div>
        <div class="stores flex">
          <a
            href="https://play.google.com/store/apps/details?id=clubmapp.android&amp;hl=de"
            class="stores-black-gp"
          >
            <img src="assets/img/icon-googleplay.png" alt="" />
          </a>
          <a
            href="https://itunes.apple.com/de/app/clubmapp/id1539899809"
            class="stores-black-apps"
          >
            <img src="assets/img/apple-ic.png" alt="" />
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
