/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { GetExecutedDbMigrationsResponse } from '../models/get-executed-db-migrations-response';
import { GetMySqlInfoResponse } from '../models/get-my-sql-info-response';
import { GetVersionResponse } from '../models/get-version-response';

@Injectable({
  providedIn: 'root',
})
export class InformationService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiInfoPost
   */
  static readonly ApiInfoPostPath = '/api/info';

  /**
   * <seealso cref="M:ClubmappServiceLayer.Managers.InformationManager.GetVersion" />.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiInfoPost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInfoPost$Plain$Response(params?: {
  }): Observable<StrictHttpResponse<GetVersionResponse>> {

    const rb = new RequestBuilder(this.rootUrl, InformationService.ApiInfoPostPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetVersionResponse>;
      })
    );
  }

  /**
   * <seealso cref="M:ClubmappServiceLayer.Managers.InformationManager.GetVersion" />.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiInfoPost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInfoPost$Plain(params?: {
  }): Observable<GetVersionResponse> {

    return this.apiInfoPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<GetVersionResponse>) => r.body as GetVersionResponse)
    );
  }

  /**
   * <seealso cref="M:ClubmappServiceLayer.Managers.InformationManager.GetVersion" />.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiInfoPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInfoPost$Json$Response(params?: {
  }): Observable<StrictHttpResponse<GetVersionResponse>> {

    const rb = new RequestBuilder(this.rootUrl, InformationService.ApiInfoPostPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetVersionResponse>;
      })
    );
  }

  /**
   * <seealso cref="M:ClubmappServiceLayer.Managers.InformationManager.GetVersion" />.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiInfoPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInfoPost$Json(params?: {
  }): Observable<GetVersionResponse> {

    return this.apiInfoPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<GetVersionResponse>) => r.body as GetVersionResponse)
    );
  }

  /**
   * Path part for operation apiInfoMysqlPost
   */
  static readonly ApiInfoMysqlPostPath = '/api/info/mysql';

  /**
   * <seealso cref="M:ClubmappServiceLayer.Managers.InformationManager.MySqlInfo" />.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiInfoMysqlPost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInfoMysqlPost$Plain$Response(params?: {
  }): Observable<StrictHttpResponse<GetMySqlInfoResponse>> {

    const rb = new RequestBuilder(this.rootUrl, InformationService.ApiInfoMysqlPostPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetMySqlInfoResponse>;
      })
    );
  }

  /**
   * <seealso cref="M:ClubmappServiceLayer.Managers.InformationManager.MySqlInfo" />.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiInfoMysqlPost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInfoMysqlPost$Plain(params?: {
  }): Observable<GetMySqlInfoResponse> {

    return this.apiInfoMysqlPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<GetMySqlInfoResponse>) => r.body as GetMySqlInfoResponse)
    );
  }

  /**
   * <seealso cref="M:ClubmappServiceLayer.Managers.InformationManager.MySqlInfo" />.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiInfoMysqlPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInfoMysqlPost$Json$Response(params?: {
  }): Observable<StrictHttpResponse<GetMySqlInfoResponse>> {

    const rb = new RequestBuilder(this.rootUrl, InformationService.ApiInfoMysqlPostPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetMySqlInfoResponse>;
      })
    );
  }

  /**
   * <seealso cref="M:ClubmappServiceLayer.Managers.InformationManager.MySqlInfo" />.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiInfoMysqlPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInfoMysqlPost$Json(params?: {
  }): Observable<GetMySqlInfoResponse> {

    return this.apiInfoMysqlPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<GetMySqlInfoResponse>) => r.body as GetMySqlInfoResponse)
    );
  }

  /**
   * Path part for operation apiInfoMigrationsPost
   */
  static readonly ApiInfoMigrationsPostPath = '/api/info/migrations';

  /**
   * <seealso cref="M:ClubmappServiceLayer.Managers.InformationManager.GetExecutedDbMigrations" />.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiInfoMigrationsPost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInfoMigrationsPost$Plain$Response(params?: {
  }): Observable<StrictHttpResponse<GetExecutedDbMigrationsResponse>> {

    const rb = new RequestBuilder(this.rootUrl, InformationService.ApiInfoMigrationsPostPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetExecutedDbMigrationsResponse>;
      })
    );
  }

  /**
   * <seealso cref="M:ClubmappServiceLayer.Managers.InformationManager.GetExecutedDbMigrations" />.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiInfoMigrationsPost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInfoMigrationsPost$Plain(params?: {
  }): Observable<GetExecutedDbMigrationsResponse> {

    return this.apiInfoMigrationsPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<GetExecutedDbMigrationsResponse>) => r.body as GetExecutedDbMigrationsResponse)
    );
  }

  /**
   * <seealso cref="M:ClubmappServiceLayer.Managers.InformationManager.GetExecutedDbMigrations" />.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiInfoMigrationsPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInfoMigrationsPost$Json$Response(params?: {
  }): Observable<StrictHttpResponse<GetExecutedDbMigrationsResponse>> {

    const rb = new RequestBuilder(this.rootUrl, InformationService.ApiInfoMigrationsPostPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetExecutedDbMigrationsResponse>;
      })
    );
  }

  /**
   * <seealso cref="M:ClubmappServiceLayer.Managers.InformationManager.GetExecutedDbMigrations" />.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiInfoMigrationsPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInfoMigrationsPost$Json(params?: {
  }): Observable<GetExecutedDbMigrationsResponse> {

    return this.apiInfoMigrationsPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<GetExecutedDbMigrationsResponse>) => r.body as GetExecutedDbMigrationsResponse)
    );
  }

  /**
   * Path part for operation apiInfoExceptPost
   */
  static readonly ApiInfoExceptPostPath = '/api/info/except';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiInfoExceptPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInfoExceptPost$Response(params?: {
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, InformationService.ApiInfoExceptPostPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiInfoExceptPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInfoExceptPost(params?: {
  }): Observable<void> {

    return this.apiInfoExceptPost$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
