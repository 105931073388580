import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { LocationPageRoutingModule } from './location-routing.module';

import { LocationPage } from './location.page';
import { LocationHeaderComponent } from './components/location-header/location-header';
import { LinkyModule } from 'ngx-linky';
import { TooltipModule } from 'ng2-tooltip-directive';
import { ComponentsModule } from '@common/components/components.module';
import { TranslateModule } from '@ngx-translate/core';
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    LocationPageRoutingModule,
    LinkyModule,
    TooltipModule,
    ComponentsModule,
    TranslateModule,
  ],
  declarations: [LocationPage, LocationHeaderComponent],
})
export class LocationPageModule {}
