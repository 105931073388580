<ion-content>
  <header [search]="true"></header>

  <main>
    <div class="container mx-auto city-select-block px-5">
      <h1>Wähle deine Stadt</h1>

      <div class="grid gap-4 lg:grid-cols-6 md:grid-cols-4 sm:grid-cols-2">
        <city-item
          *ngFor="let city of locations"
          [data]="city"
          (click)="goToLocation(city)"
        ></city-item>
      </div>

      <ion-button
        expand="block"
        class="city-select-block-button"
        (click)="goToLocation()"
      >
        <ion-icon name="compass"></ion-icon>Alle Städte
      </ion-button>
    </div>
  </main>

  <footer></footer>
</ion-content>
