<ion-content>
  <header mode="small" title="About us" subtitle=""></header>

  <main class="container mx-auto px-5 py-10">
    <h2>About us</h2>
    <p>
      Clubmapp® aims to bring together users throughout the world with their
      favorite entertainment destinations, empowering a global community of
      passionate groups and individuals to explore their cultural surroundings
      and connect with each other through shared tastes and experiences.
    </p>
    <p>&nbsp;</p>
    <p>
      Under the slogan ‘Upgrade Your Nightlife’, Clubmapp is the ultimate
      nightlife and chatting application, available for download on the App
      store. The unique app locates for users the hottest and most exclusive
      clubs in their surrounding area. Registered users of the application can
      use Clubmapp’s built-in chat function to find other clubbers, flirt, and
      make new friends- in every location- elevating their nightlife experience
      to extraordinary new heights.
    </p>
    <p>&nbsp;</p>
    <p>
      Clubmapp users can also add their own content, including photos, the
      length of the queue outside the club, and the general atmosphere inside
      the club. Users can also indicate their drink, music, and nightlife
      preferences on their profile. Clubmapp is a unique collection of all
      necessary information on one interactive application, possessing the
      latest information and news from clubs all around the world, and a network
      of passionate partygoers looking to connect with other clubbers.
    </p>
  </main>

  <footer></footer
></ion-content>
