import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavController, ToastController } from '@ionic/angular';
import {ClubService} from "../../api/services/club.service";
import {ClubListResponseEntry} from "../../api/models/club-list-response-entry";
import {ProfileService} from "../../api/services/profile.service";
import {GetLocationProfileResponse} from "../../api/models/get-location-profile-response";
import {ImagePipe} from "@common/pipes/image/image";
import {SafeUrl} from "@angular/platform-browser";
import {GetClubListFilteredRequest} from "../../api/models/get-club-list-filtered-request";

@Component({
  selector: 'cm-locations',
  templateUrl: './locations.page.html',
  styleUrls: ['./locations.page.scss'],
})
export class LocationsPage {
  locations: ClubListResponseEntry[] = [];
  pages = 1;

  page = 1;
  loading = false;
  loadedAllLocations = false;
  clubs: ClubListResponseEntry[];
  location: GetLocationProfileResponse;
  _backgroundImage: SafeUrl;
  filterParams: GetClubListFilteredRequest = {};

  constructor(
    public navCtrl: NavController,
    public toastCtrl: ToastController,
    private route: ActivatedRoute,
    private clubService: ClubService,
    private profileService: ProfileService,
    private imagePipe: ImagePipe,
    private ref: ChangeDetectorRef
  ) {}

  async ngOnInit() {
    await this.loadLocations();
    navigator.geolocation.getCurrentPosition(geo => {
      this.filterParams.geoLocation = {
        latitude: geo.coords.latitude,
        longitude: geo.coords.longitude
      };
      this.filterParams.radius = 10000000;
      this.loadLocations(true);
    },() => {},{timeout: 10000});
  }

  async loadLocations(geoLocation = false) {
    this.loading = true;
    const locationId = this.route.snapshot.paramMap.get('location')?.split('-')[0];
    if (locationId) {
      await Promise.all([
        new Promise(resolve => {
          this.clubService.apiClubFilteredPost$Json({ body: { whitelistedCities: [+locationId] } }).toPromise().then(c => {
            this.clubs = c.clubListEntries;
            resolve(null);
          });
        }),
        new Promise(resolve => {
          this.profileService.apiProfileLocationPost$Json({ body: { locationId: +locationId } }).toPromise().then(c => {
            this.location = c;
            resolve(null);
          });
        }),
      ]);
      this._backgroundImage = await this.imagePipe.transform(this.location.locationLogo).toPromise();
      this.ref.markForCheck();
    } else {
      this.clubs = (await this.clubService.apiClubFilteredPost$Json({ body: geoLocation ? this.filterParams: {} }).toPromise()).clubListEntries;
    }
    this.locations = this.clubs?.slice(0, 5);
    this.loading = false;
  }

  public get backgroundImage() {
    return this._backgroundImage;
  }

  getLocations(page: number = 1) {
    // return new Promise(async (resolve, reject) => {
    //   this.api.get('locations?page=' + page, { city: this.city.name }).subscribe(
    //     async (result: any) => {
    //       this.loading = false;
    //       console.log('Load locations: ', result);
    //
    //       if (this.page > 1) {
    //         if (result.locations.length > 0) {
    //           // eslint-disable-next-line @typescript-eslint/prefer-for-of
    //           for (let i = 0; i < result.locations.length; i++) {
    //             this.locations.push(result.locations[i]);
    //           }
    //         } else {
    //           this.loadedAllLocations = true;
    //           const toast = await this.toastCtrl.create({
    //             message: 'Keine Einträge mehr vorhanden',
    //             duration: 2000,
    //           });
    //           toast.present();
    //         }
    //       } else {
    //         this.locations = result.locations;
    //       }
    //       this.pages = result.pages;
    //       resolve(true);
    //     },
    //     (err) => {
    //       this.loading = false;
    //       console.error('Cannot load the locations!', err);
    //
    //       reject(err);
    //     }
    //   );
    // });
  }

  doInfinite(infiniteScroll) {
    if (!this.loadedAllLocations) {
      this.locations = this.locations.concat(this.clubs?.slice(this.locations?.length, this.locations?.length + 5));
      this.loadedAllLocations = this.locations?.length >= this.clubs?.length;
      infiniteScroll.target.complete();
      (event.target as any).disabled = this.loadedAllLocations;
    }
  }
}
