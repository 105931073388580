<ion-content>
  <header mode="small" title="Impressum" subtitle=""></header>

  <main class="container mx-auto">
    <h3>According to § 5 TMG:</h3>
    <p>
      Clubmapp GmbH <br />
      14bis, rue Honoré Labande <br />
      98000 MONACO <br />
      MONACO
    </p>
    <p>&nbsp;</p>
    <p>
      Clubmapp GmbH <br />
      Hindenburgstraße 38 <br />
      30175 Hannover <br />
      GERMANY
    </p>
    <p>&nbsp;</p>
    <h3>Represented by:</h3>
    <p>
      Marc Thormählen <br />
      Jochen Wunder
    </p>
    <p>&nbsp;</p>
    <h3>Contact:</h3>
    <h4>Monaco:</h4>
    <p>
      Phone: +33 6 40 99 14 01 <br />
      E-Mail:
      <a
        href="mailto:info@clubmapp.com"
        onclick="_gaq.push(['_trackEvent','mailto','info@clubmapp.com']);"
        >info@clubmapp.com</a
      >
    </p>
    <p>&nbsp;</p>
    <h4>Germany:</h4>
    <p>
      Phone: +49 511 5 90 69 69 <br />
      Fax: +49 511 67 69 1111 <br />
      E-Mail:
      <a
        href="mailto:info@clubmapp.com"
        onclick="_gaq.push(['_trackEvent','mailto','info@clubmapp.com']);"
        >info@clubmapp.com</a
      >
    </p>
    <p>&nbsp;</p>
    <h3>Registration:</h3>
    <p>
      Entry in the commercial register. <br />
      Register court: Amtsgericht Hannover <br />
      Register number: HRB 211142
    </p>
    <p>&nbsp;</p>
    <h3>Tax-ID:</h3>
    <p>
      Sales tax identification number according to §27 a Umsatzsteuergesetz:
      DE295307832
    </p>
    <p>&nbsp;</p>
    <h3>Sources for the images and graphics used:</h3>
    <p>Coming soon</p>
    <p>&nbsp;</p>
    <h3>Disclaimer</h3>
    <h4>Liability for content</h4>
    <p>
      As a service provider we are responsible according to § 7 paragraph 1 of
      TMG for our own content on these pages under the general laws. According
      to §§ 8 to 10 TMG we are not obliged as a service provider to monitor
      transmitted or stored foreign information or to investigate circumstances
      that indicate illegal activity. Obligations to remove or block the use of
      information under the general laws remain unaffected. However, a relevant
      liability is only possible from the date of knowledge of a specific
      infringement. Upon notification of such violations, we will remove the
      content immediately.
    </p>
    <p>&nbsp;</p>
    <h4>Liability for Links</h4>
    <p>
      Our offer contains links to external websites over which we have no
      control. Therefore we can not accept any responsibility for their content.
      The respective provider or operator is always responsible for the contents
      of any linked site. The linked sites were checked at the time of linking
      for possible legal violations. Illegal contents were at the time of
      linking. A permanent control of the linked pages is unreasonable without
      concrete evidence of a violation. Upon notification of violations, we will
      remove such links immediately.
    </p>
    <p>&nbsp;</p>
    <h4>Copyright</h4>
    <p>
      The content and work on these pages created by the site operator are
      subject to German copyright law. Duplication, processing, distribution and
      any kind of exploitation outside the limits of copyright require the
      written consent of the respective author or creator. Downloads and copies
      of these pages are only permitted for private, non-commercial use. As far
      as the content is not created by the website operator, the copyrights of
      third parties need to be respected. In particular, third party content as
      such. Should you nevertheless become aware of copyright infringement, we
      kindly ask for a hint. Upon notification of violations, we will remove
      such content immediately.
    </p>
    <p>&nbsp;</p>
    <h3>Trademark</h3>
    <p>
      The Clubmapp ® trademark has been filed and registered. The trademark
      application which supervised by Horak law firm who are specialists in
      trademark law.
    </p>
  </main>

  <footer></footer>
</ion-content>
