import { Component, Input } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import {SafeUrl} from "@angular/platform-browser";

@Component({
  selector: 'header',
  templateUrl: 'header.html',
  styleUrls: ['./header.scss'],
})
export class HeaderComponent {
  @Input() search: boolean;
  @Input() mode: string;

  @Input() title = 'Finde Dein Event';
  @Input() subtitle =
    'Ein Klick und die Stadt gehört dir - alle Events deiner Stadt - entdecke den Moment!';
  @Input() backbutton: boolean;

  @Input() backgroundimage: SafeUrl|any =
    'assets/img/wallpaper/alcohol-bar-party-cocktail.jpg';

  mobileMenu = false;

  items: any = [
    {
      title: 'Clubs',
      url: 'locations',
    },
    {
      title: 'Über uns',
      url: 'about-us',
    },
    // {
    //   title: 'Kontakt',
    //   url: 'contact',
    // },
  ];

  constructor(
    public modalCtrl: ModalController,
    public navCtrl: NavController
  ) {}

  goToPage(page) {
    console.log(page);
    this.navCtrl.navigateRoot(page.url);
  }

  newEvent() {
    return;
    // TODO fix
    // let modal = this.modalCtrl.create('NewEventPage');
    // modal.present();
  }
}
