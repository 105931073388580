import { Component, Input } from '@angular/core';
import { Functions } from '@common/services/providers';
import { NavController } from '@ionic/angular';
import {ClubListResponseEntry} from "../../../../api/models/club-list-response-entry";
import {SafeUrl} from "@angular/platform-browser";
import {ImagePipe} from "@common/pipes/image/image";

@Component({
  selector: 'location-list-entry',
  templateUrl: 'location-list-entry.html',
  styleUrls: ['./location-list-entry.scss'],
})
export class LocationListEntryComponent {
  @Input('data') data: ClubListResponseEntry;
  private _backgroundImage: SafeUrl;

  constructor(public navCtrl: NavController, public functions: Functions, private imagePipe: ImagePipe) {}

  public get backgroundImage() {
    return `url(${(this._backgroundImage as any)?.changingThisBreaksApplicationSecurity})`;
  }

  async ngOnInit(): Promise<void> {
    this._backgroundImage = await this.imagePipe.transform(this.data.logoGuid).toPromise();
  }

  goToLocation(location) {
    this.navCtrl.navigateForward(['club', [location.id, location.name, location.location].filter(a => a).join('-')]);
  }
}
