import { Component } from '@angular/core';

@Component({
  selector: 'event-item',
  templateUrl: 'event-item.html',
  styleUrls: ['./event-item.scss'],
})
export class EventItemComponent {
  text: string;

  constructor() {
    console.log('Hello EventItemComponent Component');
    this.text = 'Hello World';
  }
}
